<template>
  <div class="flex flex-col text-sm p-4">
    <div>
      <h2><span style="font-size: 14pt">Widerrufsrecht für Verbraucher</span></h2>
      <p><strong>Widerrufsbelehrung</strong></p>
      <p>
        1 Widerrufsrecht<br />Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt 14 Tage ab dem Tag, an
        dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die
        Waren in Besitz genommen haben bzw. hat. Im Falle eines Vertrages über mehrere
        Waren, die Sie im Rahmen einer einheitlichen Bestellung bestellt haben und die
        getrennt geliefert werden, beginnt die Frist an dem Tag, an dem Sie oder ein von
        Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz
        genommen haben bzw. hat.<br />&nbsp;<br />Im Falle eines Vertrages über die
        Lieferung einer Ware in mehreren Teilsendungen oder Stücken beginnt die Frist an
        dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer
        ist, die letzte Teilsendung oder das letzte Stück in Besitz genommen haben bzw.
        hat.<br />&nbsp;<br />Um Ihr Widerrufsrecht auszuüben, müssen Sie uns mittels
        einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder
        E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie
        können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht
        vorgeschrieben ist.<br />&nbsp;<br />Der Widerruf ist zu richten an:<br />&nbsp;<br />expert
        e-Commerce GmbH, Bayernstraße 4, 30855 Langenhagen
      </p>
      <!-- <p>oder per Telefon an: 0203 - 80481842</p> -->
      <p>
        oder per Telefax an: 0511/7808- 420<br />oder per E-Mail an:
        <a href="mailto:kundenservice@expert-commerce.de"
          >kundenservice@expert-commerce.de</a
        >
      </p>
      <p>
        &nbsp;<br />Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
        über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
      </p>
    </div>
    <div>
      <p><strong>Widerrufs-Formular</strong></p>
      <p>
        &nbsp;<br />(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
        Formular aus und senden Sie es zurück.)
      </p>
      <p>
        &nbsp;<br />— An <br />&nbsp;&nbsp;&nbsp; expert e-Commerce GmbH, Bayernstraße 4,
        30855 Langenhangen, <br />&nbsp;&nbsp;&nbsp; Telefax 0511/7808-420,<br />&nbsp;&nbsp;&nbsp;
        Mail:
        <a href="mailto:kundenservice@expert-commerce.de"
          >kundenservice@expert-commerce.de</a
        >
      </p>
      <p>
        &nbsp; <br />— Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
        abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die Erbringung der
        folgenden Dienstleistung (*)
      </p>
      <p>
        &nbsp;<br />— Bestellt am (*)/erhalten am (*) <br />— Name des/der Verbraucher(s)
        <br />— Anschrift des/der Verbraucher(s)
      </p>
      <p>
        &nbsp;<br />— Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
        <br />— Datum
      </p>
    </div>
    <div>
      <p>
        2 Folgen des Widerrufs&nbsp;<br />Wenn Sie diesen Vertrag widerrufen, haben wir
        Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
        Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass
        Sie eine andere Art der Lieferung als die von uns angebotene, günstigste
        Standardlieferung gewählt haben), unverzüglich und spätestens binnen 14 Tagen ab
        dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrages
        bei uns eingegangen ist. Für diese Rückzahlung verwenden wir das selbe
        Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es
        sei denn, mit Ihnen wurde ausdrücklich etwas Anderes vereinbart; in keinem Fall
        werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.<br />&nbsp;<br />Wir
        können die Rückzahlung verweigern, bis wir die Waren wieder zurück erhalten haben
        oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben,
        je nachdem, welches der frühere Zeitpunkt ist.<br />&nbsp;<br />Sie haben die
        Waren unverzüglich und in jedem Fall spätestens 14 Tage ab dem Tag, an dem Sie uns
        über den Widerruf dieses Vertrages unterrichten, an uns zurückzusenden oder zu
        übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von 14
        Tagen absenden.<br />&nbsp;<br />Wenn die Waren normal mit der Post zurückgesandt
        werden können, tragen wir die Kosten der Rücksendung, wenn Sie für die Rücksendung
        das Label benutzen, das wir Ihnen auf Anforderung zur Verfügung stellen. Wenn Sie
        das Label nicht benutzen, tragen Sie die unmittelbaren Kosten der Rücksendung der
        Waren. Diese Kosten werden auf höchstens etwa 35 Euro geschätzt. Wenn die Waren
        nicht normal mit der Post zurückgesandt werden können (weil Versand mit einer
        Spedition), tragen Sie die unmittelbaren Kosten der Rücksendung der Waren. Die
        Höhe der Rücksendekosten entspricht dabei der Höhe der Hinsendekosten, wenn Sie
        den von uns beauftragten Spediteur auch für die Rücksendung beauftragen. Die Höhe
        der Hinsendekosten wird Ihnen vor Abschluss der Bestellung mitgeteilt. Falls Sie
        einen anderen als den von uns beauftragten Spediteur mit der Rücksendung
        beauftragen, werden die Kosten auf höchstens etwa 112 Euro geschätzt.<br />&nbsp;<br />Sie
        müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser
        Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und
        Funktionsweisen der Waren nicht notwendigen Umgang von Ihnen zurückzuführen
        ist.“<br />&nbsp;<br />3 Ausnahmen vom Widerrufsrecht<br />Ein Widerrufsrecht
        besteht nicht bei Verträgen zur Lieferung von Ton- oder Videoaufnahmen oder
        Computersoftware in einer versiegelten Packung, wenn die Versiegelung nach der
        Lieferung entfernt wurde. Ein Widerrufsrecht besteht ferner nicht bei Verträgen
        zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der
        Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der
        Lieferung entfernt wurde.<br />&nbsp;<br />4 Widerrufsrecht bei digitalen
        Inhalten<br />Wenn Sie vor der Ausführung des Vertrages ausdrücklich zustimmen,
        dass mit der Ausführung des Vertrages vor Ablauf der Widerrufsfrist begonnen wird,
        verlieren Sie dadurch Ihr Widerrufsrecht.<br />&nbsp;<br /><strong
          >Ende der Widerrufsbelehrung<br
        /></strong>
      </p>
      <p>
        <br />5 Jegliche Rücksendung hat ausschließlich in einer transportsicheren
        Verpackung zu erfolgen. Die Verkaufsverpackungen selbst, ohne gesonderte
        Transportverpackung, sind nicht für einen sicheren Versand geeignet.<br />&nbsp;<br />6&nbsp;
        Für Zwecke der Rücksendung können Sie auf verschiedenen Wegen die
        Rücksende-Unterlagen anfordern. Sie haben zum Beispiel die Möglichkeit, uns über
        das
        <a href="https://www.expert.de/Footer/Service/Kontaktformular"
          >Kontaktformular
        </a>
        oder speziell über die Seite
        <a href="https://www.expert.de/mein-expert/retoure-suchen"
          >Retouren &amp; Widerruf</a
        >
        zu kontaktieren. Alternativ können Sie die Rücksende-Unterlagen per E-Mail an&nbsp;<a
          href="mailto:kundenservice@expert-commerce.de"
          target="_self"
          >kundenservice@expert-commerce.de</a
        >&nbsp;anfordern. Die Nutzung der Rücksendeunterlagen stellt keine zwingende
        Voraussetzung zur wirksamen Ausübung des Widerrufsrechts dar. Sie können die
        Leistungen, bezüglich derer Sie Ihre Vertragserklärung widerrufen, auch bei Ihrem
        betreuenden expert Fachmarkt (laut Rechnung) zurückgeben.&nbsp;
      </p>
    </div>
  </div>
</template>
<style scoped>
a {
  @apply text-primary cursor-pointer;
}
p {
  @apply pb-2;
}
h2 {
  @apply py-4;
}
</style>
